import Crepe1 from '../img/imagesSlider/Crepe1.jpg';
import Crepe2 from '../img/imagesSlider/crepe2.jpg';
import Crepe3 from '../img/imagesSlider/crepe3.jpg';
import Crepe4 from '../img/imagesSlider/crepe4.jpg';
import Carte from '../img/Carte.pdf';

function CardCrepe(){
    return (
        <div className='crepes'>
            <h2 className='titleCrepes'>Quelques unes de mes crêpes</h2>
            <div className='cardCrepe'>
                <img src={Crepe1} alt=""/>
                <img src={Crepe2} alt=""/>
                <img src={Crepe3} alt=""/>
                <img src={Crepe4} alt=""/>
            </div>
            <div className='link'>
                <a href={Carte}>Découvrir la carte</a>
            </div>
        </div>
    )
}

export default CardCrepe;